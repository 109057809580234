export const environment = {
  production: true,
  subscriptionKey: '40dd440f2fc4469190778c923e86f2a3',
  idAppRegistrationSPA: '1c1a4e83-11dc-4687-a01e-bc92b0bb40cc', // APP registration inside tenant b2c
  authorityB2C: 'https://login.microsoftonline.com/b5a7339a-5f84-41da-b571-bed9924fe968', // Endpoint of APP registration inside tenant b2c
  frontendRedirectURL: 'https://quantafi-dev.cmspi.com', // page to redirect
  authenticationCodeAPIM: 'yky6BhWbct839Aai9IPvaiRoF0tBHGzVDXxtdEm5UHdjCApCZCxNNw',
  sessionStorageTokenKey: 'b2c_1a_signup_signin.b5a7339a-5f84-41da-b571-bed9924fe968-cmspidev.b2clogin.com-idtoken',
  sessionStorageInfo: 'b2c_1a_signup_signin.b5a7339a-5f84-41da-b571-bed9924fe968-cmspidev.b2clogin.com',
  sessionRefreshTokenInfo: 'b2c_1a_signup_signin.b5a7339a-5f84-41da-b571-bed9924fe968-cmspidev.b2clogin.com-refreshtoken',
  userDataReplaceId: '-b2c_1a_signup_signin.b5a7339a-5f84-41da-b571-bed9924fe968',
  b2cDomain: 'cmspidev',
  apiURL: 'https://apim-cmspi-portal-dev-001.azure-api.net/func-cmspi-portal-dev-001',
  measurementID: 'G-2DSHJ3PWHY' //Google Analytics Measurement ID
};
